<template>
  <EbzPopup
    v-model="opened"
    v-bind="popupProps"
    @after-leave="emit('afterLeave')"
  >
    <div class="ebz-process-popup">
      <EbzImage
        class="absolute z-2 left-[-0.75rem] top-[-2.5rem] w-10rem max-w-420px pointer-events-none"
        :src="popupTapeSrc"
        :loading-src="popupTapeSrcLQ"
        :metadata="popupTapeMetadata"
      ></EbzImage>

      <EbzPopupClose class="absolute z-2 right-1.5rem top-1rem"></EbzPopupClose>

      <EbzRgb :zoom="75" class="ebz-process-popup__content py-6 px-2 md:px-6">
        <slot></slot>
      </EbzRgb>
    </div>
  </EbzPopup>
</template>

<script lang="ts">
export const makeProcessPagePopupProps = propsFactory(
  {
    ...makeEbzPopupProps(),
  },
  "process-page-popup"
);
</script>

<script lang="ts" setup>
import { EbzImage } from "@/components/EbzImage";
import popupTapeSrc from "@/assets/img/pg-3/popup-tape.png?format=webp&quality=85&width=420&imagetools";
import popupTapeSrcLQ from "@/assets/img/pg-3/popup-tape.png?format=webp&quality=50&width=32&imagetools";
import popupTapeMetadata from "@/assets/img/pg-3/popup-tape.png?format=webp&width=420&metadata&imagetools";
import { propsFactory } from "@/common/props";
import { EbzPopup, makeEbzPopupProps } from "@/components/EbzPopup";
import { useVModel } from "@vueuse/core";
import { EbzPopupClose } from "@/components/EbzPopupClose";
import EbzRgb from "@/components/EbzRgb/EbzRgb.vue";

const props = defineProps(makeProcessPagePopupProps());
const emit = defineEmits(["update:modelValue", "afterLeave"]);
const opened = useVModel(props, "modelValue", emit);

const popupProps = makeEbzPopupProps.reactiveFilter(props, "modelValue");
</script>

<style lang="scss">
.ebz-process-popup {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  color: var(--ebz-c-white);

  &__content {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    min-height: 100%;
    z-index: 0;
    overflow: hidden;

    .ebz-rgb__bg {
      clip-path: polygon(
        0 0,
        100% 0,
        100% 100%,
        2rem 100%,
        0 calc(100% - 2rem)
      );
    }
  }
}
</style>
